import React from "react";
import { setCookie } from "../Utils/Cookies.js";

export default function Logoutc() {
  setCookie("token", "", -1);

  window.location.href = "/login";

  return (
    <div
      className="loginBackground"
      style={{
        backgroundImage: "url(/Images/blurredScreen.jpg)",
        display: `flex`,
        width: `100vw`,
      }}
    >
      <div
        style={{
          position: `absolute`,
          color: `white`,
          top: `50%`,
          right: `45%`,
          fontSize: `50px`,
        }}
      >
        Logging Out...
      </div>
    </div>
  );
}
