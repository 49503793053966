import React from "react";
import Layout from "../Components/layout";
import Logoutc from "../Components/logoutc";
import { Seo } from "../Components/seo";


export default function Logout() {
  return (
    <Layout>
      <Logoutc />
    </Layout>
  )
}

export const Head = () => (
  <Seo />
)